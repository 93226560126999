<template>
  <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_260_704)">
      <path opacity="0.2" d="M15.9282 1.56735H1.46939C0.793469 1.56735 0 2.34613 0 3.03674V14.0571C0 14.7478 0.793469 15.5265 1.46939 15.5265H19.8367C20.5127 15.5265 21.3061 14.7478 21.3061 14.0571V8.5249C20.6872 9.08165 19.9228 9.4511 19.102 9.59021V9.64898C19.102 10.1339 18.8376 10.3837 18.3673 10.3837H2.20408C1.73388 10.3837 1.46939 10.1339 1.46939 9.64898V8.91429C1.46939 8.42939 1.73388 8.1796 2.20408 8.1796H15.0833C14.6592 7.7096 14.3416 7.1535 14.1524 6.54938C13.9632 5.94525 13.9068 5.30738 13.9871 4.67942C14.0673 4.05147 14.2823 3.44827 14.6173 2.91114C14.9523 2.374 15.3995 1.91561 15.9282 1.56735V1.56735Z" fill="black"/>
      <path d="M14.209 3.77144H0V5.97552H14.018C13.8945 5.23648 13.9602 4.47822 14.209 3.77144Z" fill="black"/>
    </g>
    <path d="M18.8571 10.2857C17.4931 10.2857 16.185 9.74388 15.2205 8.77941C14.2561 7.81493 13.7142 6.50683 13.7142 5.14286C13.7142 3.77889 14.2561 2.47078 15.2205 1.50631C16.185 0.541835 17.4931 0 18.8571 0C20.2211 0 21.5292 0.541835 22.4936 1.50631C23.4581 2.47078 23.9999 3.77889 23.9999 5.14286C23.9999 6.50683 23.4581 7.81493 22.4936 8.77941C21.5292 9.74388 20.2211 10.2857 18.8571 10.2857ZM16.8146 3.01224H16.2342L14.8163 3.73224V4.46694L15.9404 3.87918V7.27347H16.822V3.01224H16.8146ZM18.5044 3.6C18.9232 3.6 19.2171 3.8351 19.2171 4.17306C19.2171 4.54041 18.8718 4.79755 18.3722 4.79755H18.1518V5.42204H18.4163C18.9453 5.42204 19.3053 5.68653 19.3053 6.06857C19.3053 6.43592 18.9526 6.68571 18.453 6.68571C18.0857 6.68571 17.7183 6.56816 17.3363 6.34041V7.0751C17.7477 7.25143 18.1591 7.34694 18.5632 7.34694C19.5257 7.34694 20.1869 6.85469 20.1869 6.14204C20.1869 5.64245 19.8783 5.23837 19.364 5.07673C19.8048 4.9298 20.0914 4.54041 20.0914 4.09959C20.0914 3.40898 19.4816 2.93878 18.5999 2.93878C18.2025 2.94318 17.8101 3.02816 17.4465 3.18857V3.90857C17.7991 3.7102 18.1591 3.6 18.5044 3.6ZM21.6783 5.20163C22.2146 5.20163 22.5893 5.51755 22.5893 5.92898C22.5893 6.36245 22.2146 6.66367 21.6783 6.66367C21.3551 6.66367 21.0171 6.56082 20.6718 6.34776V7.10449C21.0318 7.26612 21.3991 7.34694 21.7591 7.34694C21.9502 7.34694 22.1265 7.31755 22.2955 7.27347C22.6775 6.64898 22.8979 5.92898 22.8979 5.15755L22.8832 4.84898C22.61 4.68729 22.2969 4.60581 21.9795 4.61388C21.8473 4.61388 21.7077 4.62122 21.5608 4.64327V3.68816H22.6187C22.527 3.453 22.4138 3.22675 22.2808 3.01224H20.7967V5.32653C21.0906 5.25306 21.3844 5.20163 21.6783 5.20163Z" fill="black"/>
    <defs>
      <clipPath id="clip0_260_704">
        <rect width="23.5102" height="15.4286" fill="white" transform="translate(0 1.56735)"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
  export default {
    name: "cvcCard"
  }
</script>

<style scoped>

</style>