<template>
  <div class="stripe_card">
    <template v-if="paymentMethodButtonsAvailable">
      <div class="payment_button__label">
        <span>One click checkout with</span><hr/>
      </div>
      <div id="payment-request-button" class="payment_button"></div>
    </template>
    <div class="card_info__label">
      <span>Checkout with card</span><hr/>
    </div>
    <div class="user_info input_group_wrapper">
      <label for="firstName">
        <span>Name</span>
        <span class="subText">Same as on credit card</span>
      </label>
      <div>
        <input v-model="userName" placeholder="First Name" id="firstName" class="input"/>
        <input v-model="userLastName" placeholder="Last Name" class="input"/>
      </div>
    </div>
    <div class="card_info input_group_wrapper">
      <label>Card Information</label>
      <div class="card-number_wrapper">
        <div id="card-number" class="input"/>
        <card-brands class="icons"/>
      </div>
      <div class="card_additional_numbers">
        <div id="card-expiry" class="input"/>
        <div class="card-number_wrapper">
          <div id="card-cvv" class="input"/>
          <cvc-card class="icons"/>
        </div>
      </div>
    </div>
    <div class="location_info input_group_wrapper">
      <label>Location</label>
      <select-component
          :data="$options.COUNTRIES"
          prop-for-display="name"
          prop-for-value="code"
          placeholder="Select"
          selected-value="US"
          class="select"
      />
      <input
          v-model="postalCode"
          placeholder="Postal"
          class="input"
      />
    </div>
    <slot :confirmCard="confirmCard"/>
  </div>
</template>

<script>
  import {loadStripe} from "@stripe/stripe-js";
  import {STRIPE_API_VERSION} from "../../const";
  import {mapState} from "vuex";
  import CardBrands from "../../assets/img/cardBrands";
  import CvcCard from "../../assets/img/cvcCard";
  import SelectComponent from "../base-components/SelectComponent";
  import countries from "../../views/countries";

  const inputStyle = {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      backgroundColor: '#fff',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }

  export default {
    name: "StripeCard",
    components: {SelectComponent, CvcCard, CardBrands},
    COUNTRIES: countries,
    props: {
      name: {
        type: String,
        required: true,
      },
      price: {
        type: [Number, String],
        required: true,
      },
    },
    data(){
      return {
        elements: {},
        paymentRequest: {},
        paymentMethodButtonsAvailable: false,
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null,
        userName: null,
        userLastName: null,
        postalCode: null,
      }
    },
    computed:{
      ...mapState({
        channelSettings: state => state.channel.settings,
        user: state => state.auth.user
      }),
    },
    methods: {
      /**
       * Initialize Stripe component
       * @returns {Promise<boolean>}
       * @private
       */
      async initStripe() {
        if (!this.channelSettings.stripe) {
          return false;
        }
        this.stripe = await loadStripe(this.channelSettings.stripe['stripe-public-key'], {apiVersion: STRIPE_API_VERSION});
        this.elements = this.stripe.elements();
        this.createCardElement();
        this.initPaymentRequest();
        this.initElementListeners();
      },
      createCardElement(){
        this.cardNumber = this.elements.create('cardNumber', {style: inputStyle, showIcon: true});
        this.cardNumber.mount('#card-number');
        this.cardExpiry = this.elements.create('cardExpiry', {style: inputStyle});
        this.cardExpiry.mount('#card-expiry');
        this.cardCvc = this.elements.create('cardCvc', {style: inputStyle});
        this.cardCvc.mount('#card-cvv');
      },
      initPaymentRequest(){
        // 2. Create a payment request object
        this.paymentRequest = this.stripe.paymentRequest({
          country: 'US',
          currency: this.channelSettings?.currency?.toLowerCase() || 'usd',
          total: {
            label: name,
            amount: Math.round(parseFloat(String(this.price)) * 100),
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });

        const prButton = this.elements.create('paymentRequestButton', {
          paymentRequest: this.paymentRequest,
        });

        this.paymentRequest.canMakePayment()
            .then((result) => {
              if (result) {
                this.paymentMethodButtonsAvailable = true;
                this.$nextTick(() => prButton.mount('#payment-request-button'))
              } else {
                console.log('Apple Pay support not found. Check the pre-requisites above and ensure you are testing in a supported browser.');
              }
            });

      },
      initElementListeners(){
        this.cardNumber.on('change', event => {
          this.$emit('errors', event.error? event.error.message: null)
        });
        this.cardNumber.once('change', () => this.$emit('first_change', true));
        this.paymentRequest.on('paymentmethod', (e) => this.$emit('payment_method', e));
      },
      confirmCard(orderData, e = null){
        return this.stripe.confirmCardSetup(
            orderData.client_secret, {
              payment_method: e?.paymentMethod?.id || {
                card: this.cardNumber
              }}
        )
      },
    },
    mounted() {
      this.initStripe();
      this.$emit('confirmCardCb', this.confirmCard);
      this.userName = this.user.first_name;
      this.userLastName = this.user.last_name;
    }
  }
</script>

<style scoped lang="scss">
  .stripe_card{
    --section-label_color: #757A80;
    --section-label_line_color: #003876;
    --input-label_color: #30313D;
    --input-label_secondary_color: #6A7383;
    --input-background: #FFFFFF;
    --input-border_color: #D5DBE1;
    --input-placeholder_color: #A3ACBA;
    font-weight: 600;
    text-transform: none;
    color: var(--section-label_color);
    label{
      font-size: .9rem;
    }
    input{
      width: 100%;
    }
  }
  .payment_button__label,
  .card_info__label{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
    hr{
      flex-grow: 1;
      background-color: var(--section-label_line_color);
    }
  }
  .input_group_wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.3rem;
    &:last-child{
      margin-bottom: 0;
    }
    > label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span {
        color: var(--input-label_color);
        &.subText{
          color: var(--input-label_secondary_color);
        }
      }
    }
  }
  .user_info{
    > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      gap: .5rem;
    }
  }
  .card_info{
    display: flex;
    flex-direction: column;
    gap: .3rem;
    .card-number_wrapper{
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      .icons{
        position: absolute;
        right: 1rem;
      }
    }
    .input{
      width: 100%;
    }
    .card_additional_numbers{
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      gap: .5rem;
    }
  }

  .input,
  .select{
    background-color: var(--input-background);
    padding: 10px 8px;
    border: 1px solid var(--input-border_color);
    border-radius: 4px;
    line-height: 1.2rem;
    font-size: .9rem;
    &::placeholder{
      color: var(--input-placeholder_color);
    }
  }
  .select{
    padding: 2px 0;
    width: 100%;
  }

</style>